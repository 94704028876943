var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "host-toolbar" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "background-dark",
          staticStyle: { "justify-content": "space-between" },
          attrs: { app: _vm.isToolbarVisible, dark: "", height: 40, flat: "" },
        },
        [
          _c(
            "div",
            { staticClass: "host-toolbar__col" },
            [
              _c(
                "RtbGenericTip",
                {
                  attrs: {
                    placement: "bottom",
                    path: "fluid",
                    active: _vm.firstStepFtux,
                    offset: { distance: 250, shift: 160 },
                    arrow: "",
                    "tip-animation": "fade-up",
                    "arrow-offset-end": { x: "50%", y: "130%" },
                    "arrow-offset-start": { x: "30%", y: 20 },
                    "start-socket": "top",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            {
                              staticClass: "my-0 text-center",
                              staticStyle: { width: "300px" },
                            },
                            [
                              _vm._v(
                                " Here is where you can load in more activities "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "120px" } },
                    [
                      _vm.activityState.value
                        ? _c(
                            "RtbControlButton",
                            {
                              staticClass: "host-toolbar__activity-btn",
                              attrs: { sm: "" },
                              on: { click: _vm.closeActivityPanel },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  key: "close",
                                  staticStyle: { margin: "-4px 0" },
                                  attrs: { dark: "" },
                                },
                                [_vm._v("close")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "RtbControlButton",
                            {
                              staticClass: "host-toolbar__activity-btn",
                              attrs: { sm: "" },
                              on: { click: _vm.openActivtyPanel },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "controller",
                                  width: "20",
                                  height: "20",
                                },
                              }),
                              _vm.isModerator
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "host-toolbar__activity-title",
                                    },
                                    [_vm._v(" Activities ")]
                                  )
                                : _vm._e(),
                              _c("SvgIcon", {
                                staticClass: "ml-1",
                                attrs: {
                                  name: "chevron-right-regular",
                                  size: "small",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
            ],
            1
          ),
          _c("div", { staticClass: "host-toolbar__middle host-toolbar__col" }, [
            _c(
              "div",
              {
                staticClass: "host-toolbar__controls",
                class: { "host-toolbar__controls--working": _vm.working },
              },
              [
                _c(
                  "div",
                  { staticClass: "host-toolbar__modifiers" },
                  [
                    !_vm.isModerator
                      ? _c(
                          "RtbControlButton",
                          {
                            staticClass: "mr-1",
                            attrs: { xs: "" },
                            on: { click: _vm.backUp },
                          },
                          [
                            _c("SvgIcon", {
                              attrs: {
                                name: "chevron-left-regular",
                                height: "14",
                                width: "14",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "d-flex items-center relative" },
                      [
                        _c(
                          "RtbGenericTip",
                          {
                            attrs: {
                              placement: "bottom",
                              path: "fluid",
                              active: _vm.firstStepFtux,
                              "show-delay": 500,
                              offset: { distance: 110, shift: -40 },
                              arrow: "",
                              "tip-animation": "fade-up",
                              "arrow-offset-end": { x: "50%", y: "140%" },
                              "arrow-offset-start": { x: "50%", y: 15 },
                              "start-socket": "top",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm.isHostToolsVisible
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "my-0 text-center",
                                            staticStyle: { width: "300px" },
                                          },
                                          [
                                            _vm._v(
                                              " Navigate to different activities here "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticClass: "my-0 text-center",
                                            staticStyle: { width: "250px" },
                                          },
                                          [
                                            _vm._v(
                                              " Your current activity is here "
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("SelectorControl", {
                              ref: "missionSelector",
                              staticClass: "host-toolbar__missions mr-1",
                              attrs: {
                                label: "Missions",
                                list: _vm.missionList,
                                "current-value": _vm.computedMissionID,
                                "disabled-input":
                                  _vm.working || !_vm.isHostToolsVisible,
                              },
                              on: { onSelect: _vm.onSelectMission },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.isHostToolsVisible
                                    ? {
                                        key: "append-button",
                                        fn: function () {
                                          return [
                                            _c(
                                              "RtbGenericTip",
                                              {
                                                attrs: {
                                                  placement: "bottom",
                                                  path: "fluid",
                                                  active: _vm.firstStepFtux,
                                                  "show-delay": 800,
                                                  offset: {
                                                    distance: 180,
                                                    shift: 80,
                                                  },
                                                  arrow: "",
                                                  "tip-animation": "fade-up",
                                                  "arrow-offset-end": {
                                                    x: "50%",
                                                    y: "130%",
                                                  },
                                                  "arrow-offset-start": {
                                                    x: "50%",
                                                    y: 20,
                                                  },
                                                  "start-socket": "top",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "my-0 text-center",
                                                              staticStyle: {
                                                                width: "300px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Reset the activity here "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  707240132
                                                ),
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticStyle: {
                                                      height: "100%",
                                                      margin: "0",
                                                    },
                                                    attrs: {
                                                      flat: "",
                                                      small: "",
                                                      icon: "",
                                                    },
                                                    on: {
                                                      click: _vm.resetGame,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          "font-size":
                                                            "17px !important",
                                                        },
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v(" replay ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.isHostToolsVisible
                          ? [
                              _vm.mission !== null && _vm.mission.activityId
                                ? _c("SelectorControl", {
                                    staticClass: "host-toolbar__modes",
                                    attrs: {
                                      label: "Steps",
                                      list: _vm.stepList,
                                      "current-value": _vm.selectedStep,
                                      "disabled-input": _vm.working,
                                    },
                                    on: { onSelect: _vm.onSelectStep },
                                  })
                                : _c("SelectorControl", {
                                    staticClass: "host-toolbar__modes",
                                    attrs: {
                                      label: "Steps",
                                      list: _vm.modeItems,
                                      "current-value": _vm.currentModeIndex,
                                      "disabled-input": _vm.working,
                                    },
                                    on: { onSelect: _vm.onSelectMode },
                                  }),
                            ]
                          : _vm._e(),
                        _vm.working
                          ? _c("v-progress-linear", {
                              staticClass: "host-toolbar__progress-linear",
                              attrs: { height: "3", indeterminate: "" },
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    !_vm.isModerator
                      ? _c(
                          "RtbControlButton",
                          {
                            staticClass: "ml-1",
                            attrs: { xs: "" },
                            on: { click: _vm.next },
                          },
                          [
                            _c("SvgIcon", {
                              attrs: {
                                name: "chevron-right-regular",
                                height: "14",
                                width: "14",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isModerator
                      ? _c("SettingsSelector", {
                          staticClass: "ml-4",
                          attrs: {
                            "default-title": "Go",
                            "default-icon": "directions_run",
                            "selected-prop": "name",
                            items: _vm.goItems,
                          },
                          model: {
                            value: _vm.selectedGoOption,
                            callback: function ($$v) {
                              _vm.selectedGoOption = $$v
                            },
                            expression: "selectedGoOption",
                          },
                        })
                      : _vm._e(),
                    _vm.isModerator
                      ? _c(
                          "RtbControlButton",
                          {
                            staticClass: "host-toolbar__activity-btn",
                            attrs: { sm: "" },
                            on: { click: _vm.openEditTeamsDialog },
                          },
                          [
                            _c("SvgIcon", {
                              attrs: {
                                name: "recent-actors-outlined",
                                width: "20",
                                height: "20",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "host-toolbar__activity-title" },
                              [_vm._v("Edit Teams")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isModerator &&
                    (_vm.isMeeting || _vm.isGameOverMissionType)
                      ? _c("SettingsSelector", {
                          staticClass: "mx-4",
                          attrs: {
                            "default-title": "Show",
                            "default-icon": "visibility",
                            "selected-prop": "name",
                            "disabled-select": "",
                            items: _vm.showToggles,
                          },
                          on: { input: _vm.callToggle },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append-item",
                                fn: function (ref) {
                                  var item = ref.item
                                  var callAction = ref.callAction
                                  return [
                                    item.multiple
                                      ? _c("RtbToggleButton", {
                                          staticClass:
                                            "host-toolbar__toggle-group",
                                          attrs: {
                                            value: item.value,
                                            items: item.items,
                                            disabled: item.disable,
                                          },
                                          on: {
                                            change: function (event) {
                                              return callAction({
                                                action: item.name,
                                                event: event,
                                              })
                                            },
                                          },
                                        })
                                      : _c("WeveToggle", {
                                          staticClass: "ml-auto",
                                          attrs: {
                                            checked: item.value,
                                            label: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return callAction(item.name)
                                            },
                                          },
                                        }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            825166821
                          ),
                        })
                      : _vm._e(),
                    !_vm.isMeeting
                      ? [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "RtbControlButton",
                                          _vm._g(
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                sm: "",
                                                active: _vm.votingAnon,
                                                hidden: !_vm.isVoting,
                                              },
                                              on: {
                                                click: _vm.toggleVotingAnon,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("SvgIcon", {
                                              attrs: {
                                                width: "20",
                                                height: "20",
                                                name: "anonymous",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2859716234
                              ),
                            },
                            [
                              _c("span", { staticClass: "bottom" }, [
                                _vm._v(" Anonymous Voting "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "host-toolbar__btn-group" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "RtbGenericTip",
                                              {
                                                attrs: {
                                                  placement: "bottom",
                                                  path: "fluid",
                                                  active: _vm.secondStepFtux,
                                                  offset: {
                                                    distance: 100,
                                                    shift: 100,
                                                  },
                                                  arrow: "",
                                                  "tip-animation": "fade-up",
                                                  "arrow-offset-end": {
                                                    x: "50%",
                                                    y: "130%",
                                                  },
                                                  "arrow-offset-start": {
                                                    x: "40%",
                                                    y: 20,
                                                  },
                                                  "start-socket": "top",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "my-0 text-center",
                                                              staticStyle: {
                                                                width: "300px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Let auto pilot take over "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "RtbControlButton",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        sm: "",
                                                        active:
                                                          _vm.game.autopilot,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleAutopilot()
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("SvgIcon", {
                                                      staticStyle: {
                                                        margin: "0 -4px",
                                                      },
                                                      attrs: {
                                                        name: "autopilot-white",
                                                        width: "30",
                                                        height: "20",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1827907820
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "bottom" },
                                    [
                                      _vm.game.autopilot
                                        ? [
                                            _vm._v(
                                              " Auto Pilot is on and game will continue on its own "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " Auto Pilot is Off and you can use space bar to advance "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm.isHostToolsVisible && _vm.mission
                                ? [
                                    _vm.mission.behavior ===
                                    _vm.MissionType.JeopardAI
                                      ? _c("JeopardAiResetBtn")
                                      : _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "RtbControlButton",
                                                        _vm._g(
                                                          {
                                                            attrs: { sm: "" },
                                                            on: {
                                                              click:
                                                                _vm.resetSubmission,
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("SvgIcon", {
                                                            attrs: {
                                                              width: "20",
                                                              height: "20",
                                                              name: "replay",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1229965707
                                            ),
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "bottom" },
                                              [_vm._v(" Reset Activity ")]
                                            ),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                              _vm.isHostToolsVisible && _vm.isHuddleMode
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "RtbControlButton",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        sm: "",
                                                        active:
                                                          _vm.isBroadcastToAll,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.toggleBroadcastToAll,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { dark: "" } },
                                                      [_vm._v("emoji_people")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1532020758
                                      ),
                                    },
                                    [
                                      _c("span", { staticClass: "bottom" }, [
                                        _vm._v("Broadcast to all"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isHostToolsVisible
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "RtbControlButton",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        sm: "",
                                                        active: _vm.enlargeUI,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.enlargeUI =
                                                            !_vm.enlargeUI
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { dark: "" } },
                                                      [_vm._v("text_increase")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1946286971
                                      ),
                                    },
                                    [
                                      _c("span", { staticClass: "bottom" }, [
                                        _vm._v("Increase Presenter UI"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.isModerator
                            ? _c(
                                "div",
                                { staticClass: "host-toolbar__audio-title" },
                                [
                                  !_vm.everyoneCanHearOnlyHost
                                    ? _c("v-icon", [_vm._v("volume_up")])
                                    : _c("v-icon", [_vm._v("volume_muted")]),
                                  _vm._v(" Room Audio: "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c(
                      "RtbGenericTip",
                      {
                        attrs: {
                          placement: "bottom",
                          path: "fluid",
                          active: _vm.secondStepFtux,
                          "show-delay": 500,
                          offset: { distance: 150, shift: 200 },
                          arrow: "",
                          "tip-animation": "fade-up",
                          "arrow-offset-end": { x: "70%", y: "130%" },
                          "arrow-offset-start": { x: "30%", y: 20 },
                          "start-socket": "top",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass: "my-0 text-center",
                                    staticStyle: { width: "250px" },
                                  },
                                  [_vm._v(" Control who can hear who here ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("SettingsSelector", {
                          staticClass: "host-toolbar__audio-options",
                          class: _vm.audioOptionLock
                            ? "host-toolbar__audio-options--with-lock"
                            : null,
                          attrs: {
                            "default-title": "Auto",
                            "default-svg-icon": "user-record",
                            items: _vm.audioOptions,
                          },
                          model: {
                            value: _vm.audioOption,
                            callback: function ($$v) {
                              _vm.audioOption = $$v
                            },
                            expression: "audioOption",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "host-toolbar__right host-toolbar__col" },
            [
              _c("game-music-player"),
              _c(
                "RtbGenericTip",
                {
                  attrs: {
                    placement: "bottom",
                    path: "fluid",
                    active: _vm.secondStepFtux,
                    "show-delay": 800,
                    offset: { distance: 250, shift: -160 },
                    arrow: "",
                    "tip-animation": "fade-up",
                    "arrow-offset-end": { x: "30%", y: "130%" },
                    "arrow-offset-start": { x: "70%", y: 20 },
                    "start-socket": "top",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "p",
                            {
                              staticClass: "my-0 text-center",
                              staticStyle: { width: "300px" },
                            },
                            [_vm._v(" Music and SFX are here ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_c("AudioDrawerToggle")],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("EndGamePopup", { ref: "endGamePopup" }),
      _vm.editGameSettings
        ? _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.editGameSettings,
                persistent: "",
                "no-click-animation": "",
                width: "1024px",
              },
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.passedGame },
                on: { close: _vm.closeEditSettings },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }