var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "popup-overlay end-game-popup",
      style: { visibility: _vm.isOpen ? "visible" : "hidden" },
      attrs: { "d-flex": "", "justify-center": "", "align-center": "" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "popup" } },
        [
          _vm.isOpen
            ? _c(
                "v-flex",
                {
                  staticClass: "popup-content",
                  attrs: { "d-flex": "", "pa-4": "" },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _vm.cloneStep === 1
                        ? _c(
                            "v-layout",
                            { attrs: { column: "", "justify-center": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center popup-text",
                                  attrs: { shrink: "", "mt-2": "" },
                                },
                                [
                                  _vm._v(
                                    " You can end the game or copy it to play again soon. "
                                  ),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    shrink: "",
                                    "mt-4": "",
                                    "justify-center": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        "justify-space-between": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "rtb-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onEndGame.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" End Game ")]
                                      ),
                                      _c(
                                        "rtb-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.cloneStep = 2
                                            },
                                          },
                                        },
                                        [_vm._v(" Copy ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _vm.cloneStep === 2
                        ? _c(
                            "v-layout",
                            { attrs: { column: "", "justify-center": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center popup-text",
                                  attrs: { shrink: "", "mt-4": "" },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Start the game in how many minutes?"
                                    ),
                                  ]),
                                  _c("v-text-field", {
                                    staticClass:
                                      "start-in mt-2 v-text-field--white",
                                    attrs: { required: "" },
                                    model: {
                                      value: _vm.startIn,
                                      callback: function ($$v) {
                                        _vm.startIn = $$v
                                      },
                                      expression: "startIn",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    shrink: "",
                                    "mt-2": "",
                                    "justify-center": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        "justify-space-between": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "rtb-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onCreateCopy.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Create ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "close-symbol",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onClose.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }