<template>
  <div class="selector-control">
    <div class="selector-control__actions">
      <span
        v-if="$listeners['onPrevious'] && !hideLeftArrow"
        class="selector-control__left-arrow"
      >
        <v-btn
          :disabled="isLeftArrowDisabled"
          @click="$emit('onPrevious')"
          icon
        >
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
      </span>
      <WeveSelectField
        class="selector-control__select-mission_next"
        :value="currentValue"
        :options="list"
        :label="label"
        option-label="name"
        option-value="id"
        autoscroll
        hide-label
        ref="missionSelector"
        :disabled="disabledInput"
        @input="id => $emit('onSelect', inputPayload(id))"
      >
        <template #selected-option="{ item }">
          <div class="selector-control__select-mission_next__selected">
            <b>{{ item.name }}</b>
          </div>
        </template>
        <template #option="{ icon, name, isDisabled, type }">
          <div
            class="selector-control__select-mission_next__title"
            :class="{
              'selector-control__item-with-opacity':
                type === 'semi-transparent',
              'selector-control__select-mission_next__title--disabled':
                isDisabled
            }"
          >
            <svg-icon v-if="icon" :name="icon" size="small" />
            {{ name }}
          </div>
        </template>
      </WeveSelectField>
      <slot name="append-button">
        <span
          v-if="$listeners['onNext'] && !hideRightArrow"
          class="selector-control__right-arrow"
        >
          <v-btn :disabled="isRightArrowDisabled" @click="$emit('onNext')" icon>
            <v-icon> keyboard_arrow_right </v-icon>
          </v-btn>
        </span>
      </slot>
    </div>
  </div>
</template>

<script>
import { WeveSelectField } from "@weve/ui"

export default {
  name: "SelectorControl",
  components: {
    WeveSelectField
  },
  props: {
    currentValue: {
      type: [String, Number]
    },
    list: {
      type: Array,
      required: true
    },
    label: {
      type: String
    },
    disabledInput: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledPreviousButton: {
      type: Boolean,
      default: false
    },
    hideLeftArrow: {
      type: Boolean,
      default: false
    },
    hideRightArrow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLeftArrowDisabled() {
      return (
        this.list?.[0]?.id === this.currentValue || this.disabledPreviousButton
      )
    },
    isRightArrowDisabled() {
      const { list, currentValue } = this
      return list[list.length - 1]?.id === currentValue
    },
    inputPayload() {
      return id => this.list.find(item => item.id === id) ?? {}
    }
  }
}
</script>
<style lang="scss">
.selector-control {
  display: flex;

  &__item-with-opacity {
    opacity: 0.5;
  }

  &__actions {
    display: flex;

    background: #393b42;
    border-radius: 4px;
    max-width: 210px;
  }

  &__left-arrow {
    width: 24px;
    justify-content: flex-start;

    .v-icon {
      font-size: 27px;
      color: #9aa1ad !important;
    }

    button {
      width: 20px;
      height: 100% !important;
      margin: 0 !important;
    }
  }

  &__right-arrow {
    width: 24px;
    justify-content: flex-end;

    .v-icon {
      font-size: 27px;
      color: #9aa1ad !important;
    }

    button {
      width: 20px;
      height: 100% !important;
      margin: 0 !important;
    }
  }

  &__select-mission_next {
    width: 100%;
    position: relative;

    &__title {
      text-transform: capitalize;
      &--disabled {
        opacity: 0.5;
        // color: #586475;
      }
    }

    .vs--single .vs__selected {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .weve-select__top {
      margin-bottom: 0px;
    }

    .vs__dropdown-option {
      color: #fff;

      &--selected {
        color: $color-primary-dark !important;
      }

      &--highlight {
        color: #2d2c2a !important;
        background: rgb(255, 255, 255, 0.4);
      }
    }

    .vs__dropdown-toggle {
      height: 28px;
      background: rgb(255, 255, 255, 0.5);
      border: 1px solid rgba(60, 60, 60, 0.26) !important;

      .vs__selected,
      .weve-select__open-indicator {
        color: #2d2c2a;
      }
    }

    &.weve-select--disabled .weve-select__open-indicator {
      display: none !important;
    }

    .vs__dropdown-menu {
      width: calc(100% + 86px);
      max-height: 600px;
      background-color: #6e6f73;
      border: 1px solid rgba(60, 60, 60, 0.26) !important;
    }

    .weve-select__option {
      padding: unset;
    }

    .vs__dropdown-toggle[aria-expanded="true"] {
      outline: none;
      border-color: none;
      box-shadow: none;
    }

    .vs--disabled .vs__dropdown-toggle {
      cursor: not-allowed;
      background-color: #383b41;
    }

    .vs--disabled .vs__search {
      cursor: not-allowed;
      background-color: unset;
    }
  }

  &__select-mission {
    width: 2200px;
    display: flex;
    align-items: center;

    &__title {
      text-transform: capitalize;
      &--disabled {
        color: #2c0e0ea6;
      }
    }

    .v-icon {
      font-size: 15px;
      color: #9aa1ad;
    }

    .v-input--is-disabled {
      .v-input__append-inner {
        display: none !important;
      }
    }

    &.v-select--is-menu-active {
      background: #4e4f55;
    }
    .v-input__slot {
      font-size: 15px;
      label {
        text-align: center;
        width: 100%;
        color: #fff;
        padding-left: 28px;
      }
      input {
        text-align: center;
        padding-left: 28px;
        padding-top: 0;
        padding-bottom: 2px;
        user-select: none;
      }
      &:before,
      &:after {
        display: none;
      }
      .v-input__append-inner {
        margin: 0;
      }
    }
    .v-label--active {
      display: none;
    }
    .v-text-field__details {
      display: none;
    }
  }

  .v-select__slot {
    input {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: bold;
      padding-left: 20px;
    }
  }
}
</style>
