import { db } from "@/firebase"
import { store } from "@/store"

export default class UtilsService {
  static updateBroadcastToAll(value) {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID
    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/broadcastToAll`)
      .set(value)
  }
}
