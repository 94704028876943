<template>
  <div class="host-toolbar">
    <v-toolbar
      :app="isToolbarVisible"
      dark
      :height="40"
      flat
      class="background-dark"
      style="justify-content: space-between"
    >
      <div class="host-toolbar__col">
        <RtbGenericTip
          placement="bottom"
          path="fluid"
          :active="firstStepFtux"
          :offset="{ distance: 250, shift: 160 }"
          arrow
          tip-animation="fade-up"
          :arrow-offset-end="{ x: '50%', y: '130%' }"
          :arrow-offset-start="{ x: '30%', y: 20 }"
          start-socket="top"
        >
          <template #content>
            <p style="width: 300px" class="my-0 text-center">
              Here is where you can load in more activities
            </p>
          </template>
          <div style="width: 120px">
            <RtbControlButton
              v-if="activityState.value"
              class="host-toolbar__activity-btn"
              sm
              @click="closeActivityPanel"
            >
              <v-icon dark key="close" style="margin: -4px 0">close</v-icon>
            </RtbControlButton>
            <RtbControlButton
              v-else
              class="host-toolbar__activity-btn"
              sm
              @click="openActivtyPanel"
            >
              <SvgIcon name="controller" width="20" height="20" />
              <div v-if="isModerator" class="host-toolbar__activity-title">
                Activities
              </div>
              <SvgIcon name="chevron-right-regular" size="small" class="ml-1" />
            </RtbControlButton>
          </div>
        </RtbGenericTip>

        <v-spacer />
      </div>
      <div class="host-toolbar__middle host-toolbar__col">
        <div
          class="host-toolbar__controls"
          :class="{ 'host-toolbar__controls--working': working }"
        >
          <div class="host-toolbar__modifiers">
            <RtbControlButton
              v-if="!isModerator"
              xs
              class="mr-1"
              @click="backUp"
            >
              <SvgIcon name="chevron-left-regular" height="14" width="14" />
            </RtbControlButton>
            <div class="d-flex items-center relative">
              <RtbGenericTip
                placement="bottom"
                path="fluid"
                :active="firstStepFtux"
                :show-delay="500"
                :offset="{ distance: 110, shift: -40 }"
                arrow
                tip-animation="fade-up"
                :arrow-offset-end="{ x: '50%', y: '140%' }"
                :arrow-offset-start="{ x: '50%', y: 15 }"
                start-socket="top"
              >
                <template #content>
                  <p
                    v-if="isHostToolsVisible"
                    style="width: 300px"
                    class="my-0 text-center"
                  >
                    Navigate to different activities here
                  </p>
                  <p v-else style="width: 250px" class="my-0 text-center">
                    Your current activity is here
                  </p>
                </template>
                <SelectorControl
                  ref="missionSelector"
                  label="Missions"
                  class="host-toolbar__missions mr-1"
                  :list="missionList"
                  :current-value="computedMissionID"
                  :disabled-input="working || !isHostToolsVisible"
                  @onSelect="onSelectMission"
                >
                  <template v-if="!isHostToolsVisible" #append-button>
                    <RtbGenericTip
                      placement="bottom"
                      path="fluid"
                      :active="firstStepFtux"
                      :show-delay="800"
                      :offset="{ distance: 180, shift: 80 }"
                      arrow
                      tip-animation="fade-up"
                      :arrow-offset-end="{ x: '50%', y: '130%' }"
                      :arrow-offset-start="{ x: '50%', y: 20 }"
                      start-socket="top"
                    >
                      <template #content>
                        <p style="width: 300px" class="my-0 text-center">
                          Reset the activity here
                        </p>
                      </template>
                      <v-btn
                        @click="resetGame"
                        flat
                        style="height: 100%; margin: 0"
                        small
                        icon
                      >
                        <v-icon small style="font-size: 17px !important">
                          replay
                        </v-icon>
                      </v-btn>
                    </RtbGenericTip>
                  </template>
                </SelectorControl>
              </RtbGenericTip>
              <template v-if="isHostToolsVisible">
                <SelectorControl
                  v-if="mission !== null && mission.activityId"
                  class="host-toolbar__modes"
                  label="Steps"
                  :list="stepList"
                  :current-value="selectedStep"
                  :disabled-input="working"
                  @onSelect="onSelectStep"
                />
                <SelectorControl
                  v-else
                  class="host-toolbar__modes"
                  label="Steps"
                  :list="modeItems"
                  :current-value="currentModeIndex"
                  :disabled-input="working"
                  @onSelect="onSelectMode"
                />
              </template>
              <v-progress-linear
                height="3"
                v-if="working"
                indeterminate
                class="host-toolbar__progress-linear"
              />
            </div>
            <RtbControlButton v-if="!isModerator" xs class="ml-1" @click="next">
              <SvgIcon name="chevron-right-regular" height="14" width="14" />
            </RtbControlButton>
            <SettingsSelector
              v-if="!isModerator"
              v-model="selectedGoOption"
              class="ml-4"
              default-title="Go"
              default-icon="directions_run"
              selected-prop="name"
              :items="goItems"
            />
            <RtbControlButton
              v-if="isModerator"
              class="host-toolbar__activity-btn"
              sm
              @click="openEditTeamsDialog"
            >
              <SvgIcon name="recent-actors-outlined" width="20" height="20" />
              <div class="host-toolbar__activity-title">Edit Teams</div>
            </RtbControlButton>

            <SettingsSelector
              v-if="!isModerator && (isMeeting || isGameOverMissionType)"
              class="mx-4"
              default-title="Show"
              default-icon="visibility"
              selected-prop="name"
              disabled-select
              :items="showToggles"
              @input="callToggle"
            >
              <template #append-item="{ item, callAction }">
                <RtbToggleButton
                  :value="item.value"
                  :items="item.items"
                  :disabled="item.disable"
                  @change="event => callAction({ action: item.name, event })"
                  class="host-toolbar__toggle-group"
                  v-if="item.multiple"
                />
                <WeveToggle
                  v-else
                  :checked="item.value"
                  label=""
                  class="ml-auto"
                  @change="callAction(item.name)"
                />
              </template>
            </SettingsSelector>

            <template v-if="!isMeeting">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <RtbControlButton
                    sm
                    v-on="on"
                    :active="votingAnon"
                    :hidden="!isVoting"
                    class="ml-2"
                    @click="toggleVotingAnon"
                  >
                    <SvgIcon width="20" height="20" name="anonymous" />
                  </RtbControlButton>
                </template>
                <span class="bottom"> Anonymous Voting </span>
              </v-tooltip>

              <div class="host-toolbar__btn-group">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <RtbGenericTip
                      placement="bottom"
                      path="fluid"
                      :active="secondStepFtux"
                      :offset="{ distance: 100, shift: 100 }"
                      arrow
                      tip-animation="fade-up"
                      :arrow-offset-end="{ x: '50%', y: '130%' }"
                      :arrow-offset-start="{ x: '40%', y: 20 }"
                      start-socket="top"
                    >
                      <template #content>
                        <p style="width: 300px" class="my-0 text-center">
                          Let auto pilot take over
                        </p>
                      </template>
                      <RtbControlButton
                        v-on="on"
                        sm
                        :active="game.autopilot"
                        @click="toggleAutopilot()"
                      >
                        <SvgIcon
                          name="autopilot-white"
                          width="30"
                          height="20"
                          style="margin: 0 -4px"
                        />
                      </RtbControlButton>
                    </RtbGenericTip>
                  </template>

                  <span class="bottom">
                    <template v-if="game.autopilot">
                      Auto Pilot is on and game will continue on its own
                    </template>

                    <template v-else>
                      Auto Pilot is Off and you can use space bar to advance
                    </template>
                  </span>
                </v-tooltip>

                <template v-if="isHostToolsVisible && mission">
                  <JeopardAiResetBtn
                    v-if="mission.behavior === MissionType.JeopardAI"
                  />
                  <v-tooltip v-else bottom>
                    <template #activator="{ on }">
                      <RtbControlButton sm v-on="on" @click="resetSubmission">
                        <SvgIcon width="20" height="20" name="replay" />
                      </RtbControlButton>
                    </template>
                    <span class="bottom"> Reset Activity </span>
                  </v-tooltip>
                </template>
                <v-tooltip bottom v-if="isHostToolsVisible && isHuddleMode">
                  <template #activator="{ on }">
                    <RtbControlButton
                      sm
                      v-on="on"
                      :active="isBroadcastToAll"
                      @click="toggleBroadcastToAll"
                    >
                      <v-icon dark>emoji_people</v-icon>
                    </RtbControlButton>
                  </template>
                  <span class="bottom">Broadcast to all</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isHostToolsVisible">
                  <template #activator="{ on }">
                    <RtbControlButton
                      sm
                      v-on="on"
                      :active="enlargeUI"
                      @click="enlargeUI = !enlargeUI"
                    >
                      <v-icon dark>text_increase</v-icon>
                    </RtbControlButton>
                  </template>
                  <span class="bottom">Increase Presenter UI</span>
                </v-tooltip>
              </div>

              <div v-if="isModerator" class="host-toolbar__audio-title">
                <v-icon v-if="!everyoneCanHearOnlyHost">volume_up</v-icon>
                <v-icon v-else>volume_muted</v-icon>
                Room Audio:
              </div>
            </template>
            <RtbGenericTip
              placement="bottom"
              path="fluid"
              :active="secondStepFtux"
              :show-delay="500"
              :offset="{ distance: 150, shift: 200 }"
              arrow
              tip-animation="fade-up"
              :arrow-offset-end="{ x: '70%', y: '130%' }"
              :arrow-offset-start="{ x: '30%', y: 20 }"
              start-socket="top"
            >
              <template #content>
                <p style="width: 250px" class="my-0 text-center">
                  Control who can hear who here
                </p>
              </template>
              <SettingsSelector
                class="host-toolbar__audio-options"
                :class="
                  audioOptionLock
                    ? 'host-toolbar__audio-options--with-lock'
                    : null
                "
                default-title="Auto"
                default-svg-icon="user-record"
                v-model="audioOption"
                :items="audioOptions"
              />
            </RtbGenericTip>
          </div>
        </div>
      </div>
      <div class="host-toolbar__right host-toolbar__col">
        <game-music-player />

        <RtbGenericTip
          placement="bottom"
          path="fluid"
          :active="secondStepFtux"
          :show-delay="800"
          :offset="{ distance: 250, shift: -160 }"
          arrow
          tip-animation="fade-up"
          :arrow-offset-end="{ x: '30%', y: '130%' }"
          :arrow-offset-start="{ x: '70%', y: 20 }"
          start-socket="top"
        >
          <template #content>
            <p style="width: 300px" class="my-0 text-center">
              Music and SFX are here
            </p>
          </template>
          <AudioDrawerToggle />
        </RtbGenericTip>
      </div>
    </v-toolbar>

    <EndGamePopup ref="endGamePopup" />

    <v-dialog
      v-if="editGameSettings"
      :value="editGameSettings"
      persistent
      no-click-animation
      width="1024px"
    >
      <GameSettings :game="passedGame" @close="closeEditSettings" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"

import { delay, isObject } from "lodash"

import {
  KEY_A,
  KEY_B,
  KEY_CLOSE_BRACKET,
  KEY_D,
  KEY_F,
  KEY_G,
  KEY_LEFT,
  KEY_M,
  KEY_N,
  KEY_OPEN_BRACKET,
  KEY_Q,
  KEY_RETURN,
  KEY_RIGHT,
  KEY_S,
  KEY_SPACE,
  KEY_T,
  KEY_W
} from "keycode-js"

import { state as pickTeamState } from "@/components/dialogs/PickTeamsDialog.vue"
import AudioDrawerToggle from "@/components/drawers/audio/AudioDrawerToggle.vue"
import EndGamePopup, {
  ACTION_CLOSE,
  ACTION_CREATE_COPY,
  ACTION_END_GAME
} from "@/components/Popups/EndGamePopup.vue"
import SelectorControl from "@/components/SelectorControl.vue"
import SettingsSelector from "@/components/SettingsSelector.vue"
import { WeveToggle } from "@weve/ui"
import {
  RtbControlButton,
  RtbGenericTip,
  RtbToggleButton
} from "@/components/ui"
import GameMusicPlayer from "./GameMusicPlayer.vue"
import activityState, {
  open as openActivtyPanel,
  close as closeActivityPanel
} from "@/components/drawers/activity"

import { GameEvent } from "@/event"
import Role from "@shared/enums/Role"
import AudioOption from "@shared/enums/AudioOverride"
import Session from "@shared/enums/Session"
import { SelectType, Mode, Mission as MissionType } from "@shared/enums"
import Page from "@/router/Page"
import modes from "@shared/modes"
import RunStatus from "@shared/enums/Game"
import TipJar from "@shared/enums/TipJar"
import { GoItem } from "./toolbar-options"

import { MediaServerError } from "@/store/ScreenCapture"

import { syncScoreWithBreadcrumb } from "@/services/game.service"
import NavigationService from "@/services/navigation.service"
import UtilsService from "@/services/utils.service"
import RaffleService from "@/modules/raffle/services"

import MissionCollection from "@shared/MissionCollection"

import { db } from "@/firebase"
import { TournamentService } from "@/services/tournament/tournament.service"
import { updateGameWith } from "@/services/game.service"

import useQuestionCardsRef from "@/components/GroupTeams/Common/Games/QuestionCards/useQuestionCardsRef"

const serialize = string =>
  String(string).replace(/:.*/, "").replace(/ /g, "-").toLowerCase()

const ROUND_TO_IMPORT_CUSTOMER_MISSSIONS = 1

const AUDIO_OPTIONS = [
  {
    name: "All Talk",
    value: AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
  },
  {
    name: "Host Only",
    value: AudioOption.EVERYONE_CAN_HEAR_ONLY_HOST
  },
  {
    name: "Team Talk",
    value: AudioOption.EVERYONE_CAN_HEAR_TEAMMATES
  },
  {
    name: "Auto",
    value: AudioOption.AUTO
  }
]

export default {
  name: "HostToolBar",
  components: {
    GameSettings: () => import("@/components/Game/GameSettings.vue"),
    JeopardAiResetBtn: () => import("./HostToolBar/JeopardAiResetBtn.vue"),
    WeveToggle,
    RtbControlButton,
    GameMusicPlayer,
    EndGamePopup,
    SelectorControl,
    SettingsSelector,
    RtbGenericTip,
    AudioDrawerToggle,
    RtbToggleButton
  },
  setup() {
    const { databaseRef } = useQuestionCardsRef()
    function changeQuestionStep() {
      databaseRef.value.transaction(value => {
        return {
          index: 0,
          step: (value.step ?? 0) + 1
        }
      })
    }

    return {
      changeQuestionStep
    }
  },
  data() {
    return {
      db: db.auxiliary(),
      activityState,
      isModeMenuOpen: false,
      working: false,
      modeToKeep: Mode.Default,
      passedGame: null,
      editGameSettings: false,
      endGameProgress: false,
      processingScreenshotDone: null,
      missionIDOverride: null,
      backedUp: false,
      flagDialogVisibility: false,
      suggestDialogVisibility: false,
      flagMessage: "",
      subSettingsBar: {
        active: false,
        name: null,
        default: "Back"
      },
      modesList: modes,
      selectedGoOption: null,
      addActivityVisibility: false,
      currentPanel: "",
      currentSubPanel: "",
      showSubMenu: false
    }
  },
  async beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
    this.$bus.$off(GameEvent.MoveNext, this.advance)
  },
  setup() {
    return { MissionType }
  },
  async created() {
    window.addEventListener("keyup", this.onKeyUp)
    // this.$bus.$on(GameEvent.MoveNext, this.advance)
    this.raffleService = new RaffleService()
    // don't wait a result fetching
    this.applyCustomMissions()
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.missionSelector?.$refs?.missionSelector) {
        this.$refs.missionSelector.$refs.missionSelector.lastItem = 25
      }
    })
  },
  watch: {
    screenCaptureError(e) {
      if (e instanceof MediaServerError) {
        this.$info(
          "Streaming failed either due to inactivity or slow connection"
        )
      } else if (e) {
        this.$info(e.message)
      }
    },

    async selectedGoOption(value) {
      if (!value) return

      this.selectedGoOption = null

      if (value === GoItem.EDIT_TEAMS)
        return this.$router.push(`/pickteams/${this.urlID}`)

      if (value === GoItem.EDIT_ACTIVITIES) {
        const contentToolID = this.game.contentToolID ?? undefined
        const templateID = this.game.templateID ?? undefined
        const orphaned = this.game.orphaned ?? false
        if (
          contentToolID !== undefined &&
          templateID === undefined &&
          orphaned === false
        ) {
          return window.open(`/content/room/${contentToolID}`, "_blank")
        }
        if (contentToolID !== undefined) {
          if (this.game.orphaned !== true) {
            try {
              await this.$confirm({
                message:
                  "This action will create a copy of the original Weve template in Content Tool. The changes made to the created copy will only be applied this this game."
              })
            } catch {
              return
            }
          }
          return window.open(
            `/content/import?id=${contentToolID}&game=${encodeURIComponent(
              this.gameID
            )}&session=${encodeURIComponent(
              this.clientID
            )}&url=${encodeURIComponent(
              `${window.location.origin}/login/${this.clientID}`
            )}`,
            "_blank"
          )
        }
      }

      if (value === GoItem.EDIT_ACTIVITIES_LEGACY)
        return this.$router.push(`/game/${this.urlID}/settings`)

      if (value === GoItem.EDIT_GAME_SETTINGS) return this.editSettings()

      if (value === GoItem.EDIT_EVENT_SETTINGS)
        return this.$router.push({
          name: Page.SESSION_SETTINGS,
          params: { id: this.clientID }
        })

      if (value === GoItem.END_GAME) return this.onEndGame()

      if (value === GoItem.SYNC_SCORE) return this.onSyncScore()
    }
  },
  computed: {
    ...mapGetters("ScreenCapture", ["streaming"]),
    ...mapGetters("ScreenCapture", { screenCaptureError: "error" }),
    ...mapGetters({ host: "gameHost", mission: "getCurrentMission" }),
    ...mapState(["gameID", "orgID"]),
    ...mapGetters("group", ["isMultiTeam", "votingOverride", "socialOverride"]),
    ...mapGetters([
      "moderatorID",
      "everyoneCanHearOnlyHost",
      "user",
      "actualGameID",
      "settingsBar",
      "game",
      "plays",
      "gameStatus",
      "urlID",
      "missions",
      "getGameFtuxStep",
      "isHostToolsVisible",
      "missionPlaysArray"
    ]),
    ...mapGetters({
      teams: "chats",
      onlineUsers: "onlineUsersArray",
      currentMode: "getCurrentMode",
      missionID: "currentMission"
    }),
    ...mapGetters("auth", [
      "clientID",
      "hasPreGame",
      "user",
      "client",
      "isModerator",
      "isSuper"
    ]),
    isUPRPlay() {
      return (
        this.$store.getters.gameStatus?.current_mission?.behavior ===
          MissionType.UnconditionalPositiveRegard &&
        this.currentMode === Mode.Play
      )
    },
    isQuestionCards() {
      return (
        this.$store.getters.gameStatus?.current_mission?.behavior ===
        MissionType.QuestionCards
      )
    },
    isSpiralDrawingPlay() {
      return (
        this.$store.getters.gameStatus?.current_mission?.behavior ===
          MissionType.SpiralDrawing && this.currentMode === Mode.Play
      )
    },
    isBroadcastToAll() {
      return Boolean(this.gameStatus.broadcastToAll)
    },
    isHuddleMode() {
      return this.currentMode === Mode.Huddle
    },
    slideIndex() {
      return parseInt(this.gameStatus?.slidesIndex) || 0
    },
    showToggles() {
      return [
        {
          name: "Host",
          value: !this.hideHost
        },
        {
          name: "Tip Jar",
          value: this.showTipJar,
          multiple: true,
          items: this.tipJarOptions,
          disable: this.game?.tipsDisabled === true
        },
        {
          name: "Ad",
          value: this.showReferralLinkIframe
        },
        {
          name: "Survey",
          value: this.showSurvey
        }
      ]
    },
    roomRef() {
      return this.db.ref(`org/${this.orgID}/games/${this.gameID}`)
    },
    showReferralLinkIframe: {
      get() {
        return this.game?.showAd
      },
      set(value) {
        this.roomRef.child(`showAd`).set(value)
      }
    },
    showTipJar: {
      get() {
        return this.game?.showTipJar || TipJar.OFF
      },
      set(value) {
        this.roomRef.child(`showTipJar`).set(value)
      }
    },
    hideHost: {
      set(val) {
        updateGameWith(this.actualGameID, this.orgID, {
          isHostHidden: val
        })
      },
      get() {
        return this.game.isHostHidden
      }
    },
    showSurvey: {
      set(showSurvey) {
        updateGameWith(this.actualGameID, this.orgID, {
          showSurvey
        })
      },
      get() {
        return this.game.showSurvey ?? false
      }
    },
    enlargeUI: {
      get() {
        return this.$store.getters.gameStatus?.enlargedUI
      },
      set(value) {
        this.gameStatusRef.update({
          enlargedUI: value
        })
      }
    },
    goItems() {
      const items = [
        { name: GoItem.EDIT_TEAMS, svgIcon: "recent-actors-outlined" }
      ]
      if (this.user?.role === Role.HOST) {
        if (this.game?.contentToolID) {
          items.push({ name: GoItem.EDIT_ACTIVITIES, svgIcon: "games" })
        }

        items.push({ name: GoItem.EDIT_ACTIVITIES_LEGACY, svgIcon: "games" })

        items.push({
          name: GoItem.EDIT_GAME_SETTINGS,
          svgIcon: "settings-applications"
        })
        if (!this.client?.tournament) {
          items.push({
            name: GoItem.EDIT_EVENT_SETTINGS,
            svgIcon: "settings-applications"
          })
        }
        items.push({
          name: GoItem.END_GAME,
          svgIcon: "stop-solid",
          iconColor: "red"
        })
        if (this.game.importedFromBreadcrumb === true) {
          items.push({
            name: GoItem.SYNC_SCORE,
            svgIcon: "stop-solid"
          })
        }
      }
      return items
    },
    currentModeIndex() {
      const mode = this.currentMode
      return this.currentModes.findIndex(o => o.name === mode)
    },
    modeItems() {
      return this.currentModes.map((mode, idx) => ({
        id: idx,
        name: mode.name,
        originID: 0,
        pos: 0,
        isDisabled: mode.disabled
      }))
    },
    lyrics() {
      return String(this.mission?.instructions).split(";")
    },
    nOfLyrics() {
      return this.lyrics?.length
    },
    isVoting() {
      return this.currentMode === Mode.Voting
    },
    isMeeting() {
      return this.currentMode === Mode.Meeting
    },
    votingAnon() {
      return Boolean(this.gameStatus?.votingAnon)
    },
    mission() {
      return this.gameStatus?.current_mission ?? null
    },
    isOneAtATime() {
      return (
        (!!this.mission && this.mission?.behavior === MissionType.Lipdub) ||
        this.mission?.playType === "One At A Time"
      )
    },
    computedMissionID() {
      if (this.missionIDOverride !== null) {
        return this.missionIDOverride
      }
      if (this.mission !== null) {
        const findKey =
          this.mission.relationKey !== undefined
            ? { key: "relationKey", value: this.mission.relationKey }
            : this.mission.activityId !== undefined
            ? { key: "activityId", value: this.mission.activityId }
            : null
        if (findKey !== null) {
          const mission = this.missionsWithContext.find(
            mission => mission[findKey.key] === findKey.value
          )
          if (mission !== null) {
            return mission.id
          }
        }
      }
      return this.missionID
    },
    currentModes() {
      const mission = this.mission || {}
      const availableModes = this.modesList.filter(mode => mission[mode])
      return this.modesList.map(mode => {
        const disabled = !availableModes.includes(mode)
        return {
          name: mode,
          disabled
        }
      })
    },
    availableModes() {
      return this.currentModes.filter(mode => !mode.disabled)
    },
    missionsArray() {
      return MissionCollection.normalize(this.missions || {})
    },
    missionsWithContext() {
      return Object.values(
        this.missionsArray.reduce((acc, val) => {
          const id = val.relationKey ?? val.activityId ?? val.id

          if (!acc[id]) {
            acc[id] = val
          }
          return acc
        }, {})
      ).sort((a, b) => a.pos - b.pos)
    },
    stepList() {
      let hasWelcome = false
      let hasResults = false

      const filterKey =
        this.mission.relationKey !== undefined
          ? { key: "relationKey", value: this.mission.relationKey }
          : { key: "activityId", value: this.mission.activityId }
      const steps = this.missionsArray
        .filter(mission => mission[filterKey.key] === filterKey.value)
        .map(mission => {
          if (mission.welcome) {
            hasWelcome = true
          }

          if (mission.results) {
            hasResults = true
          }

          return {
            id: mission.id,
            step: true,
            name: `${mission.name.split(";")[1]}`
          }
        })

      return [
        ...steps,
        !hasWelcome && {
          name: Mode.Welcome.toUpperCase(),
          id: Mode.Welcome,
          type: "semi-transparent"
        },
        !hasResults && {
          name: Mode.Results.toUpperCase(),
          id: Mode.Results,
          type: "semi-transparent"
        }
      ].filter(Boolean)
    },
    selectedStep() {
      return [Mode.Welcome, Mode.Results].includes(this.currentMode)
        ? this.currentMode.toUpperCase()
        : this.missionID
    },
    missionList() {
      return this.missionsWithContext.map((mission, idx) => ({
        id: mission.id,
        icon: `missions/${serialize(mission.behavior)}`,
        originID: mission.originID,
        name: `${idx + 1}. ${mission.name.split(";")[0]}`
      }))
    },
    isMasterOrTemplate() {
      return (
        [Session.TEMPLATE].includes(this.client.category) ||
        [
          RunStatus.MASTER,
          RunStatus.TEMPLATE,
          RunStatus.USER_TEMPLATE
        ].includes(this.game.runStatus)
      )
    },
    tipJarOptions() {
      return [
        { name: "Auto", value: TipJar.AUTO },
        { name: "On", value: TipJar.ON },
        { name: "Off", value: TipJar.OFF }
      ]
    },
    isToolbarVisible() {
      return !this.game?.isToolbarHidden
    },
    gameStatusRef() {
      return this.db.ref(`org/${this.orgID}/game/${this.gameID}/gameStatus`)
    },
    audioOptionLock() {
      return this.gameStatus?.audioOptionLock === true
    },
    activityAudioOption() {
      return this.gameStatus?.current_mission?.audioOption || AudioOption.AUTO
    },
    audioOptions() {
      if (!this.audioOptionLock)
        return AUDIO_OPTIONS.sort((a, b) =>
          String(a.name).localeCompare(b.name)
        )
      const option = AUDIO_OPTIONS.find(
        option => option.value === this.activityAudioOption
      )
      return [
        { ...option, name: `← ${option.name}` },
        ...AUDIO_OPTIONS.filter(
          option => option.value !== this.activityAudioOption
        ).sort((a, b) => String(a.name).localeCompare(b.name))
      ]
    },
    audioOption: {
      get() {
        return this.gameStatus?.audioOption
      },
      set(value) {
        this.gameStatusRef.update({
          audioOptionLock: value !== this.activityAudioOption,
          audioOption: value
        })
      }
    },
    isGameOverMissionType() {
      return (
        this.mission?.behavior === MissionType.GameOver ||
        String(this.mission?.name).indexOf(MissionType.GameOver) > -1
      )
    },
    isTheaterVoting() {
      return this.isVoting && this.mission.theaterVoting
    },
    firstStepFtux() {
      return this.getGameFtuxStep === 1 && this.isModerator && !this.isMeeting
    },
    secondStepFtux() {
      return this.getGameFtuxStep === 2 && this.isModerator && !this.isMeeting
    },
    firstMissionOfActivity() {
      const activityId = this.mission?.activityId
      return (
        activityId &&
        this.missionsArray
          .filter(mission => {
            return mission.activityId === activityId
          })
          .sort((a, b) => a.pos - b.pos)[0]
      )
    }
  },
  methods: {
    ...mapActions([
      "updateShowScreenCaptureDialog",
      "toggleGameModeratorID",
      "toggleAutopilot",
      "updateUser"
    ]),
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions(["toggleAutopilot"]),
    ...mapActions("group", ["updateCurrentGlobalTeam"]),
    openActivtyPanel,
    closeActivityPanel,
    toggleBroadcastToAll() {
      UtilsService.updateBroadcastToAll(!this.isBroadcastToAll)
    },
    getTrw() {
      const instructions = this.mission?.instructions
      const missions = MissionCollection.normalize(this.missions)

      const countByAnswer = this.missionPlaysArray
        .map(obj => obj?.answer?.[0] ?? "")
        .reduce((acc, val) => {
          acc[val.title] = acc[val.title] ? acc[val.title] + 1 : 1
          return acc
        }, {})

      const options = missions.map(mission => {
        return {
          option: mission.title,
          missionID: mission.id,
          num: countByAnswer[mission.title] ?? 0
        }
      })

      const total = options.reduce((acc, val) => acc + val.num, 0)

      return options
        .map(obj => {
          const num = total ? (obj.num / total) * 100 : 0
          const percent = Math.round(num * 10) / 10
          return {
            ...obj,
            percent,
            instructions
          }
        })
        .sort((a, b) => b.percent - a.percent)
    },
    openEditTeamsDialog() {
      pickTeamState.open = true
    },
    showPanel(panel) {
      this.addActivityVisibility = false
      delay(() => (this.addActivityVisibility = true), 150)
      this.currentPanel = panel
    },
    showSubPanel(panel) {
      this.showSubMenu = false
      delay(() => (this.showSubMenu = true), 150)
      this.currentSubPanel = panel
    },
    back() {
      this.showSubMenu = false
      this.addActivityVisibility = true
    },
    updateShowSubMenu(val) {
      this.showSubMenu = val
    },
    closeEditSettings() {
      this.passedGame = null
      this.editGameSettings = false
    },
    editSettings() {
      // TODO replace to call edit settings window
      this.passedGame = { ...this.game }
      this.passedGame.theKey = this.gameID
      this.editGameSettings = true
    },
    async saveStatistic() {
      await TournamentService.setTeamsStatistic({
        orgID: this.game.orgID || this.orgID,
        clientID: this.clientID,
        gameID: this.gameID,
        originalGameID: this.originalGameID,
        tournamentID: this.game.tournamentID || 0,
        hostID: this.user.id
      })
    },
    async endGame() {
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.gameID,
        endTimestamp: Date.now(),
        endedBy: this.user?.id ?? null
      })
    },
    async showEndGameModal() {
      const div = document.createElement("div")

      document.body.append(div)
      const instance = new EndGamePopup().$mount()

      div.appendChild(instance.$el)

      return instance.open().finally(async () => {
        await instance.$destroy()
        div.remove()
      })
    },
    async onEndGame() {
      try {
        if (this.game.starter) throw new Error("The game has not started yet")
        if (this.game.tournamentID) {
          this.endGameProgress = true

          const teams = await TournamentService.fetchTournamentGameStatistics(
            this.clientID,
            this.game.tournamentID,
            this.originalGameID
          )

          const gameTeams = Object.values(teams || {}).filter(
            // @ts-expect-error Expect
            team => team?.gameID === this.gameID
          )

          if (!gameTeams.length) {
            await this.saveStatistic()
          } else {
            console.warn("This game has already been ended")
          }

          await this.endGame()
          await this.$info("Bye!")
        } else if (this.hasPreGame) {
          let response = await this.showEndGameModal()
          if (response.action === ACTION_CLOSE) return
          this.endGameProgress = true
          if (response.action === ACTION_CREATE_COPY) {
            // Create Copy of the game and set current as deleted
            await this.saveStatistic()
            try {
              await this.endGameAndMakeCopy(response.startIn)
            } catch (e) {
              console.error(e)
              await this.$info(e.message)
              await this.endGame()
            }
          } else if (response.action === ACTION_END_GAME) {
            await this.saveStatistic()
            await this.endGame()
          }
        } else {
          await this.endGame()
        }
      } catch (e) {
        console.error(e)
        await this.$info(e.message)
      } finally {
        this.endGameProgress = false
      }
    },
    async onSyncScore() {
      try {
        await syncScoreWithBreadcrumb(this.orgID, this.clientID, this.gameID)
      } catch (e) {
        console.error(e)
        await this.$info(e.message)
      }
    },
    async endGameAndMakeCopy(startIn) {
      const now = Date.now()
      if (!this.originalGameID) throw new Error("originalGameID is undefined")
      const newGameID = await this.$store.dispatch(
        "Games/copyFromOriginalGame",
        {
          originalGameID: this.originalGameID,
          orgID: this.game.orgID || this.orgID,
          clientID: this.clientID,
          startIn: startIn,
          hostID: this.user.id
        }
      )
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.gameID,
        endTimestamp: now,
        clientID: this.clientID,
        deletedTimestamp: now
      })
      await this.$store.dispatch("recording/copyAnnouncementVideo", {
        clientID: this.clientID,
        srcTargetID: this.gameID,
        distTargetID: newGameID
      })
      await this.$store.dispatch("auth/initializeToGame", {
        gameID: newGameID,
        clientID: this.clientID
      })
    },
    gameOver() {
      const mission = Object.values(this.missions).find(mission =>
        mission.name?.includes("Game Over")
      )
      mission && mission.id && this.navigateTo(mission.id)
    },
    gotoAwards() {
      const mission = Object.values(this.missions).find(mission =>
        mission.name?.includes("Award Show")
      )
      mission && mission.id && this.navigateTo(mission.id)
    },
    gotoResults() {
      console.log("RESULTS")
      this.navigateTo(null, "results")
    },
    async resetSubmission() {
      this.working = true
      try {
        await this.$confirm({
          message: `Are you sure you want to RESET THIS MISSION: ${this.mission?.name}?`,
          buttonColor: "danger"
        })
        await NavigationService.reset(this.orgID, this.gameID, this.missionID)
      } catch (error) {
        console.error(error)
      }
      this.working = false
    },
    async resetGame() {
      try {
        await this.$confirm({
          message:
            "Are you sure you want to RESET THE WHOLE GAME? You will need to pull in new Activity Sets",
          buttonColor: "danger"
        })
        await NavigationService.reset(this.orgID, this.gameID)
      } catch (e) {
        console.error(e)
      }
    },
    toggleVotingAnon() {
      this.$store.dispatch("updateGameStatusAny", {
        votingAnon: !this.votingAnon
      })
    },
    callAction(actionName, message) {
      if (this[actionName]) {
        this[actionName](message)
      }
    },
    nextMode() {
      const currentModeList = this.availableModes
      const idx = this.availableModes.findIndex(
        mode => mode.name === this.currentMode
      )

      const nextMode =
        idx < currentModeList.length - 1
          ? currentModeList[idx + 1]
          : currentModeList[0]
      this.navigateTo(null, nextMode.name.toLowerCase())
    },
    async next(noScribe) {
      if (this.missionIDOverride) this.missionIDOverride = null
      const { mission, orgID, gameID, currentMode: mode, nOfLyrics } = this

      if (mode === Mode.Results && mission?.trw) {
        const data = this.getTrw()
        const missionID = data[0]?.missionID
        if (missionID) {
          return this.navigateTo(missionID)
        }
      }
      if (mode === Mode.Social && mission?.behavior === MissionType.Lipdub) {
        if (this.game.autopilot) {
          this.onSelectOneScribe(true)
          return
        }

        try {
          const { id } = mission
          const { committed } = await this.db
            .ref(`org/${orgID}/game/${gameID}/lipdub/${id}/index`)
            .transaction(index => {
              if (isNaN(index)) return 0
              const newIndex = index + 1
              if (newIndex >= nOfLyrics) return
              return newIndex
            })
          if (committed) {
            if (!noScribe) {
              await NavigationService.select(orgID, gameID, "select-one")
            }
            return
          }
        } catch (e) {
          console.error(e)
        }
      }

      this.working = true

      try {
        await NavigationService.next(orgID, gameID, this.game?.experimental)
      } catch (e) {
        console.error(e)
      }

      this.working = false
    },
    async onSelectOneScribe(freez) {
      await NavigationService.select(
        this.orgID,
        this.gameID,
        SelectType.SELECT_ONE
      )
      if (this.isOneAtATime && !freez) this.next(true)
    },
    async onSelectOneScribePerTeam(freez) {
      await NavigationService.select(
        this.orgID,
        this.gameID,
        SelectType.SELECT_ONE_FROM_EACH_TEAM
      )
      if (this.isOneAtATime && !freez) this.next(true)
    },
    updateBackground(image) {
      this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.gameID,
        backgroundImageOver: image
      })
    },
    updateImage(keystroke) {
      let background
      if (keystroke === "a") {
        background = "https://media.giphy.com/media/26tOZ42Mg6pbTUPHW/giphy.gif"
        if (this.game.bgTriggerA) background = this.game.bgTriggerA
        this.updateBackground(background)
      } else if (keystroke === "s") {
        background =
          "https://media.giphy.com/media/h2O03YfHbmz28DzCXW/giphy.gif"
        if (this.game.bgTriggerS) background = this.game.bgTriggerS
        this.updateBackground(background)
      } else if (keystroke === "d") {
        background =
          "https://media.giphy.com/media/MCR9CStF7L1vmJK7DL/giphy.gif"
        if (this.game.bgTriggerD) background = this.game.bgTriggerD
        this.updateBackground(background)
      } else if (keystroke === "f") {
        background =
          "https://media.giphy.com/media/3oEjHVBkEIT0m0Xrnq/giphy.gif"
        if (this.game.bgTriggerF) background = this.game.bgTriggerFe
        this.updateBackground(background)
      }
    },
    clearBackground() {
      this.updateBackground(null)
    },
    pushToPickTeams() {
      this.$router.push(`/pickteams/${this.urlID}`)
    },
    isTeamNavigationLocked() {
      return (
        this.user?.teamID &&
        this.mission?.behavior === MissionType.DiscussionGroup
      )
    },
    nextTeam() {
      if (this.isTeamNavigationLocked()) return

      const teamIDs = Object.keys(this.teams || {})
      const index = teamIDs.findIndex(
        teamID => teamID === this.$store.getters["group/globalTeamID"]
      )
      const newTeamID =
        teamIDs.length - 1 < index + 1 ? teamIDs[0] : teamIDs[index + 1]
      if (newTeamID) return this.updateCurrentGlobalTeam(newTeamID)
    },
    prevTeam() {
      if (this.isTeamNavigationLocked()) return

      const teamIDs = Object.keys(this.teams || {})
      const index = teamIDs.findIndex(
        teamID => teamID === this.$store.getters["group/globalTeamID"]
      )
      const newTeamID =
        index - 1 < 0 ? teamIDs[teamIDs.length - 1] : teamIDs[index - 1]
      if (newTeamID) return this.updateCurrentGlobalTeam(newTeamID)
    },

    onKeyUp(e) {
      if (
        e.keyCode === KEY_RIGHT &&
        this.mission?.behavior === MissionType.JeopardAI
      )
        return

      if (
        e.keyCode === KEY_SPACE &&
        this.mission?.behavior === MissionType.JeopardAI
      )
        return

      if (
        e.keyCode === KEY_LEFT &&
        this.mission?.behavior === MissionType.JeopardAI
      )
        return

      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      )
        return

      if (
        (this.isSpiralDrawingPlay || this.isUPRPlay) &&
        e.keyCode === KEY_SPACE &&
        e.shiftKey
      )
        return this.next()

      if (
        (this.isSpiralDrawingPlay || this.isUPRPlay) &&
        e.keyCode === KEY_SPACE &&
        !e.shiftKey
      )
        return this.onSelectOneScribe(true)

      if (
        e.keyCode === KEY_SPACE &&
        this.game?.pinnedTeamID &&
        this.isQuestionCards
      ) {
        return this.changeQuestionStep()
      }

      if (e.keyCode === KEY_SPACE && !e.shiftKey) return this.next()

      if (e.keyCode === KEY_SPACE && e.shiftKey && this.isQuestionCards) {
        return this.navigateToNextMission()
      }

      if (e.keyCode === KEY_SPACE && e.shiftKey)
        return this.onSelectOneScribePerTeam()

      if (e.keyCode === KEY_RIGHT && !this.isTheaterVoting) {
        return this.overrideToNextMission()
      }
      if (e.keyCode === KEY_LEFT && !this.isTheaterVoting) {
        return this.overrideToPreviousMission()
      }

      if (e.keyCode === KEY_CLOSE_BRACKET && e.shiftKey) {
        return (this.enlargeUI = true)
      }
      if (e.keyCode === KEY_OPEN_BRACKET && e.shiftKey) {
        return (this.enlargeUI = false)
      }

      // nav between teams
      if (e.keyCode === KEY_CLOSE_BRACKET) return this.nextTeam()
      if (e.keyCode === KEY_OPEN_BRACKET) return this.prevTeam()

      if (e.keyCode === KEY_M) return this.setOnlyHostAudioOption()
      if (e.keyCode === KEY_N) return this.setAllTalkAudioOption()
      if (e.keyCode === KEY_Q) return this.selectNextPlayers()
      if (e.keyCode === KEY_RETURN) return this.applyOverrideMissionID()
      if (e.keyCode === KEY_F && e.shiftKey) this.updateImage("f")
      if (e.keyCode === KEY_S && e.shiftKey) this.updateImage("s")
      if (e.keyCode === KEY_A && e.shiftKey) this.updateImage("a")
      if (e.keyCode === KEY_D && e.shiftKey) this.updateImage("d")
      if (e.keyCode === KEY_B && e.shiftKey) this.gotoResults()
      if (e.keyCode === KEY_G && e.shiftKey) this.gameOver()
      if (e.keyCode === KEY_W && e.shiftKey) this.gotoAwards()
      if (e.keyCode === KEY_T && e.shiftKey) this.clearBackground()
    },
    backUp() {
      if (!this.isHostToolsVisible) return
      this.overrideToPreviousMission()
      this.applyOverrideMissionID()
    },
    getCurrentMissionIndex() {
      return this.missionList.findIndex(({ id }) => id === this.missionID)
    },
    applyOverrideMissionID() {
      if (!this.missionIDOverride) return
      this.navigateTo(this.missionIDOverride)
    },
    overrideToNextMission() {
      const targetID =
        this.missionIDOverride ||
        this.firstMissionOfActivity?.id ||
        this.missionID
      const index = this.missionList.findIndex(({ id }) => id === targetID)

      const newIndex = this.missionList.length - 1 < index + 1 ? 0 : index + 1

      this.missionIDOverride = this.missionList[newIndex].id
    },
    overrideToPreviousMission() {
      const targetID =
        this.missionIDOverride ||
        this.firstMissionOfActivity?.id ||
        this.missionID
      const index = this.missionList.findIndex(({ id }) => id === targetID)
      const newIndex = Math.max(index - 1, 0)

      this.missionIDOverride = this.missionList[newIndex].id
    },
    navigateToNextMission() {
      const index = this.getCurrentMissionIndex()
      const newIndex = this.missionList.length - 1 < index + 1 ? 0 : index + 1
      const newMissionID = this.missionList[newIndex].id
      const mission = this.missions[newMissionID]
      const mode = mission && this.modesList.find(mode => mission[mode])
      this.backedUp = false
      return this.navigateTo(newMissionID, mode || Mode.Default)
    },
    selectNextPlayers() {
      if (String(this.mission?.playType).includes("Individual:")) {
        NavigationService.select(this.orgID, this.gameID, SelectType.SELECT_ALL)
      } else {
        NavigationService.select(
          this.orgID,
          this.gameID,
          SelectType.SELECT_ONE_FROM_EACH_TEAM
        )
      }
    },
    async navigateTo(missionID, mode) {
      this.working = true
      if (this.missionIDOverride) this.missionIDOverride = null
      try {
        await NavigationService.navigateTo(
          this.orgID,
          this.gameID,
          missionID || this.missionID,
          mode
        )
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    setAllTalkAudioOption() {
      if (this.audioOption !== AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER) {
        this.audioOption = AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
      } else {
        this.audioOption = null
      }
    },
    setOnlyHostAudioOption() {
      if (this.audioOption !== AudioOption.EVERYONE_CAN_HEAR_ONLY_HOST) {
        this.audioOption = AudioOption.EVERYONE_CAN_HEAR_ONLY_HOST
      } else {
        this.audioOption = null
      }
    },
    onSelectStep(data) {
      if (data.step && data.id !== this.missionID) {
        this.onSelectMission(data)
      } else {
        const modeOfCurrentStep = this.modesList.find(
          mode => this.mission[mode]
        )

        this.onSelectMode({
          name: data.step ? modeOfCurrentStep : data.name
        })
      }
    },
    onSelectMission(e) {
      this.navigateTo(e?.id)
    },
    onSelectMode({ name }) {
      this.navigateTo(null, String(name).toLowerCase())
    },
    async applyCustomMissions() {
      if (this.client.disablePortalContent) {
        return console.log("Custom content is disabled")
      }
      if (this.isMasterOrTemplate) {
        return console.log(
          "Custom content is disabled for Masters and Templates"
        )
      }
      try {
        const { game } = this

        if (
          game.round &&
          parseInt(game.round) != ROUND_TO_IMPORT_CUSTOMER_MISSSIONS
        )
          return console.log("Skip customer missions import")
      } catch (e) {
        console.warn(e)
      }
    },
    callToggle(actionPayload) {
      let toggleName
      if (isObject(actionPayload)) {
        toggleName = actionPayload?.action
      } else {
        toggleName = actionPayload
      }

      const actions = {
        Host: () => {
          this.hideHost = !this.hideHost
        },
        [`Tip Jar`]: () => {
          const { action, event } = actionPayload
          if (action && event) this.showTipJar = event
        },
        [`Ad`]: () => {
          this.showReferralLinkIframe = !this.showReferralLinkIframe
        },
        ["Survey"]: () => {
          this.showSurvey = !this.showSurvey
        }
      }
      actions[toggleName]()
    }
  }
}
</script>

<style lang="scss">
.v-list__tile.primary--text {
  color: $primary_accent_color !important;
}

.v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
  height: 26px;
}

.v-input__icon .primary--text {
  color: $color-white !important;
}

.v-dialog__content {
  .v-dialog {
    overflow: visible;
  }
}

.host-toolbar {
  .theme--dark.v-toolbar {
    background-color: #292932 !important;
  }

  .selector-control__select-mission.v-input--is-disabled
    .v-input__slot
    .v-input__append-inner {
    display: none;
  }

  &__btn-group {
    display: flex;
    margin-right: 8px;
    & > *:not(:last-child) {
      margin-right: 2px;
    }
  }

  &__audio-title {
    color: #9aa1ad;
    font-weight: bold;
    margin-right: 10px;
    font-size: 12px;
    width: auto;
    display: flex;
    align-items: center;

    i {
      color: #9aa1ad !important;
      font-size: 15px;
      margin: 4px;
    }
  }

  &__reset-activity {
    width: 24px;
    justify-content: flex-end;

    .v-icon {
      font-size: 27px;
      color: #9aa1ad !important;
    }
  }

  &__activity-title {
    color: #9aa1ad;
    font-weight: bold;
    margin: 0 2px 2px 4px;
    font-size: 12px;
    width: auto;
  }

  &__activity-btn {
    min-width: 60px;
    margin: 0 8px;
  }

  &__audio-player {
    width: 225px;

    &__container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .v-toolbar__content {
    justify-content: space-between;
  }

  &__modifiers {
    display: flex;
    align-items: center;
  }

  &__missions {
    .selector-control__actions {
      max-width: 264px;
      min-width: 220px;
    }

    .selector-control__select-mission {
      width: auto;
    }
  }

  &__modes {
    .selector-control__actions {
      max-width: 152px;
      min-width: 126px;
    }

    .selector-control__select-mission {
      width: auto;
    }
  }

  &__play-stop {
    margin-left: -0px !important;
    margin-right: -5px !important;
  }

  &__record-btn {
    margin-left: -5px !important;
    margin-right: -4px !important;
  }

  &__col {
    flex-grow: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: #292932;
  }

  &__audio-options {
    .v-list__tile__title {
      box-sizing: border-box;
    }
    &--with-lock {
      .v-list__tile__title {
        border: 1px solid $primary-accent-color;
        box-shadow: 0px 0px 6px $primary-accent-color,
          0px 0px 3px $primary-accent-color;
      }
    }
    .v-list__tile {
      width: 150px;
    }
  }

  &__controls {
    position: relative;
    text-align: center;

    /* background: #222225; */
    /* box-shadow: inset 0px 1px 88px rgba(255, 255, 255, 0.04); */
    border-radius: 4px;

    &--working {
      pointer-events: none;
    }
  }

  &__right {
    position: relative;
  }

  &__btn {
    min-width: 40px;
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    background: #292932 !important;
    box-shadow: none !important;
    border-radius: 3px;

    margin: 0 2.5px;
  }

  .mission-text {
    margin-top: 0px;
  }

  .mode {
    cursor: pointer;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mission-name {
    font-weight: bold;
  }

  .direct {
    background-color: $color-red;
  }

  .v-list {
    padding: 0;
  }

  .theme--dark.v-toolbar {
    background: $color-black;
  }

  .v-toolbar {
    z-index: $layer_toolbar;

    .v-toolbar__content {
      padding: 0;
      padding-right: 0;
    }
  }

  .menu {
    width: 40px;
    margin: 15px 30px 0 0;
    cursor: pointer;

    span {
      display: block;
      height: 2px;
      margin-bottom: 8px;
      background: $primary_accent_color;
      transition: all 0.1s ease-out;
    }

    &:hover span {
      background: $color-white;
    }
  }

  .audio-player .v-btn--icon.prev-btn,
  .audio-player .v-btn--icon.next-btn {
    margin-top: 4px !important;
  }

  .v-input__slot {
    min-height: unset;
    margin-bottom: 0;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: unset;
  }

  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: $color-tertiary-dark;
    border-radius: 0;
  }

  .v-text-field,
  .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0;
  }

  .v-btn--icon:before {
    border-radius: 0;
  }

  .next-mode {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-divider--vertical {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    margin: 9px 10px;
    background: $color-grey2;
  }

  .end-game-icon {
    margin-top: 2px;
    display: inline-block;
    font-size: 23px;
    cursor: pointer;

    &:hover {
      color: $primary_accent_color;
    }
  }

  .login-player {
    margin-left: 0 !important;
    margin-right: 5px !important;
  }

  .game-editor {
    background: currentColor;
    padding: 6px 11px;
    margin-left: 10px;
    margin-right: 10px;
    height: 25px;
    line-height: 14px;
    color: $primary_accent_color;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-out;

    &:hover {
      color: $color-white;
      background: currentColor;
    }
  }

  .edit-settings {
    margin-right: 10px;
  }

  .logout {
    margin-top: 15px;
    margin-left: 10px;
    height: 22px;
    line-height: 21px;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    border: solid 1px $primary_accent_color;
    border-radius: 12px;
    font-weight: 600;
    color: $color-grey-light4;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background: $primary_accent_color;
    }
  }

  .screenshot-processing {
    animation: rotation 3s infinite linear;
  }

  .mission-badge {
    .v-badge__badge {
      height: 16px;
      width: 16px;
      font-size: 10px;
      font-weight: bold;
      left: -7px;
      top: 3px;
    }

    &.badge--imported .v-badge__badge {
      background-color: $primary_accent_color !important;
      border-color: $primary_accent_color !important;
    }

    &.badge--all:not(.badge--imported) .v-badge__badge {
      background-color: $color-grey-light3 !important;
      border-color: $color-grey-light3 !important;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .game-end-countdown {
    height: 40px;
    line-height: 40px;
    background: #303030;
    color: #fff;
    font-size: 20px;
    padding: 0 13px;

    &.end-close,
    &.ended {
      background: #eb1c22;
    }

    .text {
      padding: 0 7px 0 0;
      font-size: 14px;
      line-height: 45px;
    }

    &.ended .text {
      line-height: 40px;
      padding: 0;
    }

    .time {
      background: transparent;
      padding: 0;
    }
  }

  &__progress-linear {
    position: absolute;
    left: 0;
    bottom: -4px;
    right: 0;
    margin: 0;
    border-radius: 4px;
  }

  &__toggle-group {
    width: 147px;
    height: 25px;
    z-index: 1;
    border: 1px solid #787a7c;
    margin-left: 6px;
    .v-btn--active:before,
    .v-btn:hover:before,
    .v-btn:focus:before {
      background-color: unset;
    }
    .v-btn.v-btn--disabled {
      background: #80808042;
    }
  }
}
</style>
