var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selector-control" }, [
    _c(
      "div",
      { staticClass: "selector-control__actions" },
      [
        _vm.$listeners["onPrevious"] && !_vm.hideLeftArrow
          ? _c(
              "span",
              { staticClass: "selector-control__left-arrow" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: _vm.isLeftArrowDisabled, icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onPrevious")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("WeveSelectField", {
          ref: "missionSelector",
          staticClass: "selector-control__select-mission_next",
          attrs: {
            value: _vm.currentValue,
            options: _vm.list,
            label: _vm.label,
            "option-label": "name",
            "option-value": "id",
            autoscroll: "",
            "hide-label": "",
            disabled: _vm.disabledInput,
          },
          on: {
            input: function (id) {
              return _vm.$emit("onSelect", _vm.inputPayload(id))
            },
          },
          scopedSlots: _vm._u([
            {
              key: "selected-option",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "selector-control__select-mission_next__selected",
                    },
                    [_c("b", [_vm._v(_vm._s(item.name))])]
                  ),
                ]
              },
            },
            {
              key: "option",
              fn: function (ref) {
                var icon = ref.icon
                var name = ref.name
                var isDisabled = ref.isDisabled
                var type = ref.type
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "selector-control__select-mission_next__title",
                      class: {
                        "selector-control__item-with-opacity":
                          type === "semi-transparent",
                        "selector-control__select-mission_next__title--disabled":
                          isDisabled,
                      },
                    },
                    [
                      icon
                        ? _c("svg-icon", {
                            attrs: { name: icon, size: "small" },
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(name) + " "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._t("append-button", function () {
          return [
            _vm.$listeners["onNext"] && !_vm.hideRightArrow
              ? _c(
                  "span",
                  { staticClass: "selector-control__right-arrow" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { disabled: _vm.isRightArrowDisabled, icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("onNext")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(" keyboard_arrow_right ")])],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }